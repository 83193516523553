export class ProductsModel {

    id : string;
    menuCategoryId : string; 
    menuCategoryName : string; 
    image : string;
    name : string;
    price : number; 
    priceCurrency : string;
    contents : string;
    videoLink : string;
    menuCategoryQueue : number; 
    mainQueue : number; 
    isShowOnMain : boolean;
    isStatus : boolean;
 
    constructor(data?) {
        data = data ?? {};

        this.id = data.id ?? "";
        this.menuCategoryId = data.menuCategoryId ?? "0";
        this.menuCategoryName = data.menuCategoryName ?? "0";
        this.image = data.image ?? '';
        this.name = data.name ?? '';
        this.price = data.price ?? 0;
        this.priceCurrency = data.priceCurrency ?? '';
        this.contents = data.contents ?? '';
        this.videoLink = data.videoLink ?? ''; 
        this.menuCategoryQueue = data.menuCategoryQueue ?? 0;
        this.mainQueue = data.mainQueue ?? 0;
        this.isShowOnMain = data.isShowOnMain ?? false;
        this.isStatus = data.isStatus ?? false;
    } 
}

