export class UsersPermissions {
    customer_id: string;
    customer_name: string;
    url: string;
    logo: string;
    primary_background: string;
    secondary_background: string;
    customer_type: string;

    constructor(data?) {
        data = data ?? {};

        this.customer_id = data.customer_id ?? '';
        this.customer_name = data.customer_name ?? '';
        this.url = data.url ?? '';
        this.logo = data.logo ?? '';
        this.secondary_background = data.secondary_background ?? '';
        this.primary_background = data.primary_background ?? '';
        this.customer_type = data.customer_type ?? '';
    }
}