export class MenuCategoryModel {
    id: string;
    parentId: string;
    tenantsId: string;   
    parentName:string;   
    tenantsName:string;   
    name: string;
    icon: string;
    code: string;
    isStatus:boolean

    constructor(data?) {
        data = data ?? {};

        this.id = data.id ?? '';
        this.parentId = data.parentId ?? '';
        this.tenantsId = data.tenantsId ?? '';
        this.parentName = data.parentName ?? '';
        this.tenantsName = data.tenantsName ?? '';
        this.name = data.name ?? '';
        this.icon = data.icon ?? '';
        this.code = data.code ?? ''; 
        this.isStatus = data.isStatus ?? true; 
    } 
}