export class LanguageTranslateModel {
    id: number;
    languageId: number;
    languageName: string;   
    langCode:string;   
    value:string;   
    isStatus:boolean

    constructor(data?) {
        data = data ?? {};

        this.id = data.id ?? 0;
        this.languageId = data.languageId ?? 0;
        this.languageName = data.languageName ?? '';
        this.langCode = data.langCode ?? '';
        this.value = data.value ?? '';
        this.isStatus = data.isStatus ?? true; 
    } 
}