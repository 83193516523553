import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Router } from "@angular/router";
// import { CookieService } from "ngx-cookie-service";
import { Observable, BehaviorSubject } from "rxjs";
import { Users, UsersInfoDto, UsersPermissions } from "src/models";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
// import { NgxPermissionsService } from "ngx-permissions";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class AuthenticationService implements Resolve<any> {

    private _authenticated: boolean = false;

    constructor(
        private translate: TranslateService,
        private _httpClient: HttpClient,
        private router: Router
    ) {
        this.currentUser = JSON.parse(localStorage.getItem("currentUser") || "{}");
        this.onCurrentUserChanged = new BehaviorSubject(this.currentUser);
        this.onCustomerDetailChanged = new BehaviorSubject({});
    }


    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }


    currentUser: Users | null;
    onCurrentUserChanged: BehaviorSubject<any>;




    // customerDetail: CustomerDetail | null;
    onCustomerDetailChanged: BehaviorSubject<any>;

    // helper methods

    private refreshTokenTimeout: any;

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                //
            ]).then(() => {
                resolve();
            }, reject);
        });
    }

    login(username: string, password: string, token: string = ""): Promise<any> {

        return new Promise((resolve, reject) => {

            let body = {
                UserName: username,
                Password: password,
            };
            let path = "api/Auth/AccessToken";

            if (token.length > 10) {
                path = "api/Auth/AccessByToken?token=" + token;
            }



            this._httpClient.post(environment.apiEndpoint + path, body
            )
                .subscribe((response: any) => {

                    if (response.status) {

                        this.accessToken = response.accessToken;

                        this._authenticated = true;

                        this.currentUser = response;
                        this.onCurrentUserChanged.next(this.currentUser);
                        localStorage.setItem('currentUser', JSON.stringify(response));

                        localStorage.setItem('UserInfo', JSON.stringify(response.users));

                        //this.cookieService.set('AuthorizePermissions', JSON.stringify(response.permissions));
                        localStorage.setItem('AuthorizePermissions', JSON.stringify(response.permissions));

                    }


                    resolve(response);
                }, reject);
        });

    }

    controlAuthorizePermissions(permissions: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            var authorizePlus = "";
            if (localStorage.getItem("AuthorizePermissions") != null && localStorage.getItem("AuthorizePermissions") != undefined)
                authorizePlus = localStorage.getItem("AuthorizePermissions");
            resolve(authorizePlus.indexOf(permissions) > 0);
        });
    }

    ForgotPassword(email: string): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.post(environment.apiEndpoint + "api/Auth/ForgotPassword",
                {
                    Email: email
                })
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    ConfirmToken(code: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.apiEndpoint + "api/Auth/ConfirmToken?id=" + code)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    refreshToken(): Promise<any> {
        const currentUser = localStorage.getItem("currentUser")
            ? JSON.parse(localStorage.getItem("currentUser"))
            : null;

        if (currentUser === null || currentUser === undefined) {
            this.router.navigate(["/logout"]);
            return;
        }

        return new Promise((resolve, reject) => {
            this._httpClient
                .post(environment.apiEndpoint + "auth/refresh-token", {
                    refreshToken: currentUser.refreshToken,
                })
                .subscribe((response: any) => {
                    const expiryDate = new Date(response.Result.expiration);
                    //this.cookieService.set("accessToken", response.Result.accessToken, expiryDate);
                    localStorage.setItem("accessToken", response.Result.accessToken);

                    currentUser.expiresIn = response.Result.expiration;
                    currentUser.accessToken = response.Result.accessToken;
                    currentUser.refreshToken = response.Result.refreshToken;

                    localStorage.setItem("currentUser", JSON.stringify(currentUser));

                    this.onCurrentUserChanged.next(currentUser);

                    this.startRefreshTokenTimer();

                    resolve(response);
                }, reject);
        });
    }



    logout(): Promise<any> {
        localStorage.removeItem("currentUser");
        localStorage.removeItem("customerDetail");
        localStorage.removeItem("accessToken");
        // this.cookieService.delete("accessToken");
        // this.permissionsService.flushPermissions();
        localStorage.clear();
        this.currentUser = null;
        this.onCurrentUserChanged.next(this.currentUser);

        // Set the authenticated flag to false
        this._authenticated = false;

        this.stopRefreshTokenTimer();

        return new Promise<void>((resolve, reject) => {
            resolve();
        });
    }

    isValidUser(): Boolean {
        return (
            this.currentUser !== null &&
            this.currentUser.hasOwnProperty("accessToken")
        );
    }

    getUserInfo(): UsersInfoDto {
        let user: UsersInfoDto = new UsersInfoDto(JSON.parse(localStorage.getItem("UserInfo") || "{}"));

        // if (localStorage.getItem("UserInfo") == undefined || localStorage.getItem("UserInfo") == null || localStorage.getItem("UserInfo") == "")
        //     this.router.navigate(['sign-out']);

        return user;
    }

    getCurrentUser(): any {
        let user: any = JSON.parse(localStorage.getItem("currentUser") || "{}");

        // if (localStorage.getItem("currentUser") == undefined || localStorage.getItem("currentUser") == null || localStorage.getItem("currentUser") == "")
        //     this.router.navigate(['sign-out']);

        return user;
    }

    changeUserPassword(userName: string, newPassword: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .post(environment.apiEndpoint + "auth/change-password", {
                    userName: userName,
                    password: newPassword,
                })
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    private startRefreshTokenTimer() {
        // set a timeout to refresh the token 1 minute before it expires
        const expires = Math.round(new Date().getTime() + 3600 * 1000); // 3600 second:  60 min: 1 hours
        const timeout = expires - Date.now() - 60 * 1000;

        this.refreshTokenTimeout = setTimeout(() => this.refreshToken().then(), timeout);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }


}