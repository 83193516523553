export class Users {
    id: string;
    tenantsId: string;
    rolesId: number;
    lang: string;
    firstName: string;
    lastName: string;
    userName: string;
    email: string;
    isStatus: boolean;
    isApiUser: boolean;
    refreshToken: string;

    constructor(data?) {
        data = data || {};

        this.id = data.id || '';
        this.refreshToken = data.refreshToken || '';
        this.tenantsId = data.tenantsId || '';
        this.rolesId = data.rolesId || 0;
        this.lang = data.lang || '';
        this.firstName = data.firstName || '';
        this.lastName = data.lastName || '';
        this.userName = data.userName || '';
        this.email = data.email ?? '';
        this.isStatus = data.isStatus; 
        this.isApiUser = data.isApiUser; 
    }
}