export class ProductsOptionsModel {

    id : string;
    productsId : string; 
    productsName : string; 
    name : string;
    contents : string;
    price : number; 
    priceCurrency : string;
    mainQueue : number; 
    isStatus : boolean;
 
    constructor(data?) {
        data = data ?? {};

        this.id = data.id ?? "";
        this.productsId = data.productsId ?? "0";
        this.productsName = data.productsName ?? "0";
        this.name = data.name ?? '';
        this.contents = data.contents ?? '';
        this.price = data.price ?? 0;
        this.priceCurrency = data.priceCurrency ?? '';
        this.mainQueue = data.mainQueue ?? 0;
        this.isStatus = data.isStatus ?? false;
    } 
}

