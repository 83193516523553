export class SettingsModel {
    id: string;
    name: string;
    logo: string;
    logoBig: string;
 
    constructor(data?) {
        data = data ?? {};

        this.id = data.id ?? '';
        this.name = data.name ?? '';
        this.logo = data.logo ?? '';
        this.logoBig = data.logoBig ?? '';
    } 
}


