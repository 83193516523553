export class UsersRoles {
    id: string;
    tenantsId: string;   
    langCode: string;
    name: string;
    isAdmin: boolean;
    isStatus: boolean;

    constructor(data?) {
        data = data ?? {};

        this.id = data.id ?? '';
        this.tenantsId = data.tenantsId ?? '';
        this.langCode = data.langCode ?? '';
        this.name = data.name ?? '';
        this.isAdmin = data.isAdmin ?? '';
        this.isStatus = data.isStatus ?? false; 
    }

  
}