export class UsersInfoDto {
    id: string;
    tenantsId: string;
    tenantsName: string;
    rolesId: number;
    rolesName: string;
    lang: string;
    firstName: string;
    lastName: string;
    userName: string;
    email: string;
    isStatus: boolean;
    isLdapUser: boolean;

    constructor(data?) {
        data = data || {};

        this.id = data.id ?? '';
        this.tenantsId = data.tenantsId ?? '';
        this.tenantsName= data.tenantsName ?? '';
        this.rolesId = data.rolesId ?? 0;
        this.rolesName= data.rolesName ?? '';
        this.lang = data.lang ?? '';
        this.firstName = data.firstName ?? '';
        this.lastName = data.lastName ?? '';
        this.userName = data.userName ?? '';
        this.email = data.email ?? '';
        this.isStatus = data.isStatus; 
        this.isLdapUser = data.isLdapUser ?? false;
    }
}
