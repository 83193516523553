import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable()
export class InterceptService implements HttpInterceptor {

    constructor(
        private router: Router
    ) { }

    // intercept request and add token
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const sessionTokenExclusionPathList = [
            environment.apiEndpoint + "api/Auth/AccessToken",
            'assets/icons/material-twotone.svg',
            'assets/icons/material-outline.svg',
            'assets/icons/material-solid.svg',
            'assets/icons/iconsmind.svg',
            'assets/icons/feather.svg',
            'assets/icons/heroicons-outline.svg',
            'assets/icons/heroicons-solid.svg'
        ];

        // const cookieExists = this.cookieService.check('accessToken');
        const cookieExists = false;
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));

        if (!sessionTokenExclusionPathList.find(x => x === request.urlWithParams)) {

            if (request.headers.get('skip')) {
                // skip header means don't include authorization and user Id....
            }
            // modify request
            else if (cookieExists) {
                request = request.clone({
                    setHeaders: {
                        // Authorization: 'Bearer ' + this.cookieService.get('accessToken'),
                        //  Authorization: 'Bearer ' + this.cookieService.get('accessToken'),
                        // 'Access-Control-Allow-Origin': '*',
                        // userCode: currentUser.userCode,
                    }
                });
            }
            else if (currentUser !== null) {
                request = request.clone({
                    setHeaders: {
                        Authorization: 'Bearer ' + currentUser.accessToken,
                        'Content-Type': 'application/json',
                        // 'Access-Control-Allow-Origin': '*',
                        // userCode: currentUser.userCode,
                    }
                });
            }
        }

        return next.handle(request)
            .pipe(
                tap((event: any) => {
                    // if (event instanceof HttpResponse) {
                    //  http response status code
                    // }
                    // if (event.body = !null &&
                    //     event.body != undefined &&
                    //     event.body.StatusCode != undefined &&
                    //     event.body.StatusCode != null &&
                    //     event.body.StatusCode === 401) {
                    //     this.router.navigate(['auth/login']);
                    // }

                }, (error: any) => {

                    if (error instanceof HttpErrorResponse) {
                        if (error.status === 401) {
                            this.router.navigate(['sign-in']);
                        }
                    }

                })
            );
    }
}